const sha = require("js-sha256").sha256;
const TOKEN_CONNECTORS = "#_#";
const TIME_HALFHOUR = 30 * 60 * 1000;
const TOKEN_DOT = ".";
const sha256 = function (arg0) {
  return sha(arg0).toUpperCase();
};
const sha256Signature = function (accessKey, secretKey, expireTime) {
  var signingStr =
    accessKey + TOKEN_CONNECTORS + expireTime + TOKEN_CONNECTORS + secretKey;
  var signedStr = sha256(signingStr);
  signedStr = sha256(signedStr + TOKEN_CONNECTORS + secretKey);
  return signedStr;
};
export const generateToken = function (accessKey, secretKey, expireTime) {
  expireTime =
    new Date().getTime() +
    (expireTime >= 0 || expireTime <= TIME_HALFHOUR
      ? expireTime
      : TIME_HALFHOUR);
  var signedStr = sha256Signature(accessKey, secretKey, expireTime);
  return expireTime + TOKEN_DOT + signedStr + TOKEN_DOT + accessKey;
};
