import axios from "axios";
import { Notify } from "vant";
// create an axios instance
const service = axios.create({
  baseURL: "/qts2c", // 在这里设置访问的基路径，这样就不用在每个接口上面加了，线上也会加上这个
  timeout: 60000, // request timeout
  withCredentials: true,
});
import { generateToken } from "@/utils/sha256";
// request 拦截
service.interceptors.request.use(
  (config) => {
    config.headers["access-token"] = generateToken("ces-shareholder", "12*7345%8Abcjs", 10 * 60 * 1000); //设置请求头，带上token,登录的时候存在cookie中的
    return config;
  },
  (error) => {
    if (error.response) {
      return Promise.reject(error);
    }
  }
);
// response interceptor
service.interceptors.response.use(
  (response) => {
    if (response.data instanceof Blob) {
      return response;
    } else {
      return response.data;
    }
  },
  (error) => {
    Notify({ type: "danger", message: error.message });
    return Promise.reject(error);
  }
);

export default service;
