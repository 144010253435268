import { get, post, getPost, uploadPost } from "./requestMethods";

//查询产品信息
export const getBaseMaterialProduct = (params) => get("/emes/materialConsumer/getBaseMaterialProduct", params);

//查询质检报告列表
export const getBaseMaterialQmsUrl = (params) => get("/emes/pp/retrospect/getBaseMaterialQmsUrlH5", params);

//查询物料追溯
export const queryH5 = (params) => get("/emes/pp/retrospect/queryH5", params);

//查询assess-token
export const getAccessToken = (params) => get("/emes/materialConsumer/getAccessToken", params);

//获取scheme
export const getGenerateScheme = (params) => get("/emes/materialConsumer/getGenerateScheme", params);

//获取批次号
export const getBaseMaterialProductBatch = (params) => get("/emes/materialConsumer/getBaseMaterialProductBatch", params);
